








































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiServiceGetConfig,
    apiServiceSetConfig
} from '@/api/application/service'
import MaterialSelect from '@/components/material-select/index.vue'

@Component({
    components: {
        MaterialSelect
    }
})
export default class ServiceSetting extends Vue {
    form: any = {
        title: '',
        phone: '',
        qr_code: '',
        status: 0,
        business_time: ''
    }

    /** S Methods **/

    getServicegetConfig() {
        apiServiceGetConfig({}).then(res => {
            this.form = res
        })
    }

    onSubmit() {
        apiServiceSetConfig({ ...this.form }).then(res => {
            this.getServicegetConfig()
        })
    }

    /** E Methods **/

    /** Life Cycle **/
    created() {
        // 初始化数据
        this.getServicegetConfig()
    }
}
